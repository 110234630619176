import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { ASSETS_DOC_API, ASSETS_IMAGES_API, EMPLOYEE_API, MEMO_API } from '../../../../constants/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import { DownloadOutlined, FilePdfOutlined, FileWordOutlined } from '@ant-design/icons';
import { Image } from 'primereact/image';
import { Tooltip } from 'primereact/tooltip';
import { Menu } from 'primereact/menu';

const Memo = ({ userId }) => {
    const [employeesData, setEmployeesData] = useState([]);
    const [selectedMeme, setSelectedMeme] = useState([]);
    const [data, setData] = useState([]);
    const [globalFilter, setGlobalFilter] = useState("");

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await axios.get(EMPLOYEE_API.All_USERS);
                const { usersData } = response.data;
                setEmployeesData(usersData);
            } catch (error) {
                console.error("Error fetching employees:", error);
            }
        };

        const fetchMemoData = async () => {

            try {
                const response = await axios.get(MEMO_API.MEMO_FETCH(userId));
                setData(response.data || []);
            } catch (error) {
                console.error("Error fetching memo data:", error);
            }
        };

        fetchEmployees();
        fetchMemoData();
    }, [userId]);

    const getFilePreview = (fileUrl) => {
        if (!fileUrl) return <span className='ml-3'>-</span>;

        const extension = fileUrl.split('.').pop().toLowerCase();
        const fileUrlWithBase = `${ASSETS_DOC_API.ASSETS_DOC}${fileUrl}`;

        switch (extension) {
            case 'jpg':
            case 'jpeg':
            case 'png':
                return (
                    <div>
                        <Image src={`${ASSETS_IMAGES_API.ASSETS_IMAGES}${fileUrl}`} alt="File preview" preview width='45px' height='45px' />
                    </div>
                );
            case 'pdf':
                return (
                    <Button
                        icon={<FilePdfOutlined />}
                        className="p-button-text text-red-500 border-red-500 hover:bg-red-500 hover:text-white "
                        onClick={() => window.open(fileUrlWithBase, '_blank')}
                    />
                );
            case 'doc':
            case 'docx':
                return (
                    <Button
                        icon={<FileWordOutlined />}
                        className="p-button-text text-blue-500 border-blue-500 hover:bg-blue-500 hover:text-white"
                        onClick={() => window.open(fileUrlWithBase, '_blank')}
                    />
                );
            default:
                return <span>Unsupported file type</span>;
        }
    };
    const handleDownload = async (memeId) => {
        try {
            const url = MEMO_API.MEMO_DOWNLOAD(memeId);

            const response = await axios.get(url, {
                responseType: 'blob',
            });

            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const urlObject = URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = urlObject;
            a.download = `file_${memeId}`;
            a.click();

            URL.revokeObjectURL(urlObject);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };
    const menu = useRef(null)

    const handleContextMenu = (event, rowData) => {
        event.preventDefault();
        setSelectedMeme(rowData)
        menu.current.show(event);
    };

    return (
        <div>
            <div className="flex justify-content-end mb-2">
                <div className="w-15rem">
                    <div className="p-inputgroup">
                        <InputText
                            type="text"
                            className="search-input p-inputtext p-component"
                            placeholder="Search"
                            value={globalFilter}
                            onChange={(e) => setGlobalFilter(e.target.value)}
                        />
                        <Button icon="pi pi-search" className="search-icon" />
                    </div>
                </div>
            </div>
            <div className="">
                <div className="">
                    <DataTable value={data} paginator rows={10} globalFilter={globalFilter}>
                        <Column header="S.No" body={(rowData) => <span>{data.indexOf(rowData) + 1}</span>} />
                        <Column
                            field="date"
                            header="Date"
                            body={(rowData) => rowData.date ? moment(rowData.date).format("DD-MM-YYYY") : '-'}
                            style={{ minWidth: '120px', maxWidth: '120px' }}
                        />
                        <Column
                            field="subject"
                            header="Subject"
                            className='cursor-pointer'
                            body={(rowData) => (
                                <div>
                                    <Tooltip target={`.subject-tooltip-${rowData.id}`} content={rowData.subject} />
                                    <span
                                        className={`subject-tooltip-${rowData.id}`}
                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                    >
                                        {rowData.subject.length > 20 ? rowData.subject.slice(0, 20) + '...' : rowData.subject}
                                    </span>
                                </div>
                            )}
                        />
                        <Column
                            field="message"
                            header="Message"
                            className='cursor-pointer'
                            body={(rowData) => (
                                <div>
                                    <Tooltip target={`.message-tooltip-${rowData.id}`} content={rowData.message} />
                                    <span
                                        className={`message-tooltip-${rowData.id}`}
                                        style={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                    >
                                        {rowData.message.length > 20 ? rowData.message.slice(0, 20) + '...' : rowData.message}
                                    </span>
                                </div>
                            )}
                        />
                        <Column body={(rowData) => getFilePreview(rowData.file)} header="File" />
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

export default Memo;