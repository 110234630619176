import React, { useEffect,useState } from "react";
import { Button } from "primereact/button";
import moment from "moment";
import axios from "axios";
import { PROJECT_API, TIME_SHEET_API } from "../../../../constants/api";
import { Tooltip } from "primereact/tooltip";
import { InputText } from "primereact/inputtext";

const DailyUpdate = ({userId}) => {
    const [projectListData, setProjectListData] = useState([]);
    const [timeSheetData, setTimeSheetData] = useState([]);
    const [filteredTimeSheetData, setFilteredTimeSheetData] = useState([]);
    const [selectedDateFilter, setSelectedDateFilter] = useState(null);

    useEffect(() => {
        if (selectedDateFilter) {
            const filteredData = timeSheetData.filter(
                (entry) =>
                    moment(entry.date).format("YYYY-MM-DD") === selectedDateFilter
            );
            setFilteredTimeSheetData(filteredData);
        } else {
            setFilteredTimeSheetData(timeSheetData);
        }
    }, [selectedDateFilter, timeSheetData]);

    useEffect(() => {
        const getTimeSheetData = () => {
            axios
                .get(TIME_SHEET_API.TIME_SHEET_GET(userId))
                .then(({ data }) => {
                    setTimeSheetData(data);
                })
                .catch((error) => {
                    console.error("Error fetching user data:", error.message);
                });
        };


        const getProjectData = async () => {
            axios
                .get(PROJECT_API.GET_ALL_PROJECTS)
                .then(({ data }) => {
                    const projectsFromDB = data;
                    const projectsFormatted = projectsFromDB.map((project) => ({
                        label: project?.project_name,
                        value: project?.project_id,
                    }));
                    setProjectListData(projectsFormatted);
                })
                .catch((error) => {
                    console.error("Error fetching projects:", error);
                });
        };
        getTimeSheetData();
        getProjectData();
    }, [userId]);

    const getStatusText = (status) => {
        switch (status.toLowerCase()) {
            case "pending":
                return {
                    text: "Not yet Approved",
                    bgColor: "#EBE9F5",
                    color: "#6C28D6",
                };
            case "approved":
                return { text: "Approved", bgColor: "#615EFC", color: "#fff" };
            case "rejected":
                return { text: "Not Approved", bgColor: "#FF204E", color: "#fff" };
            default:
                return { text: status, bgColor: "#EBE9F5", color: "" };
        }
    };

    const projectTitleView = (id) => {
        const project = projectListData.find(
            (project) => project.value === parseInt(id)
        );

        return project ? project.label : "";
    };

    function calculateTotalHours(startTime, endTime) {
        const start = moment(startTime, "HH:mm");
        const end = moment(endTime, "HH:mm");
        const duration = moment.duration(end.diff(start));
        const hours = duration.hours().toString().padStart(2, "0");
        const minutes = duration.minutes().toString().padStart(2, "0");
        return `${hours}:${minutes}`;
    }

    return (
        <div>
            <div className="flex block justify-content-end align-items-center mb-2">
                <div className="w-15rem">
                    <div className="p-inputgroup">
                        <InputText
                            type="date"
                            id="dateFilter"
                            value={selectedDateFilter}
                            onChange={(e) => setSelectedDateFilter(e.target.value)}
                        />
                        <Button icon="fi fi-bs-search" className="search-icon" />
                    </div>
                </div>
            </div>

            <div className="">
                <div>
                    <div className="time-sheet-data">
                        {Object.entries(
                            filteredTimeSheetData.reduce((acc, entry) => {
                                const date = moment(entry.date).format("YYYY-MM-DD");
                                if (!acc[date]) {
                                    acc[date] = [];
                                }
                                acc[date].push(entry);
                                return acc;
                            }, {})
                        ).sort(([date1], [date2]) => moment(date2, 'YYYY-MM-DD').valueOf() - moment(date1, 'YYYY-MM-DD').valueOf())
                            .map(([date, entries]) => {
                                const totalMinutes = entries.reduce((total, entry) => {
                                    const start = moment(entry.start_time, "HH:mm");
                                    const end = moment(entry.end_time, "HH:mm");
                                    return total + end.diff(start, "minutes");
                                }, 0);
                                const totalHours = Math.floor(totalMinutes / 60);
                                const totalMinutesRemainder = totalMinutes % 60;
                                return (
                                    <div
                                        key={date}
                                        style={{
                                            background: "#eee",
                                            padding: "6px",
                                            marginBottom: "16px",
                                            borderRadius: "5px",
                                        }}
                                    >
                                        <div
                                            className=""
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                flexWrap: "wrap",
                                                gap: "8px",
                                                marginBottom: "8px",
                                                padding: "8px",
                                            }}
                                        >
                                            <div className="">
                                                <h4
                                                    style={{
                                                        minWidth: "300px",
                                                    }}
                                                >
                                                    {moment(date, "YYYY-MM-DD").format(
                                                        "dddd, MMMM D, YYYY"
                                                    )}
                                                </h4>
                                            </div>
                                            <div className="total working">
                                                Total:{" "}
                                                <span style={{ fontWeight: "bold" }}>
                                                    {totalHours}:
                                                    {totalMinutesRemainder < 10
                                                        ? `0${totalMinutesRemainder}`
                                                        : totalMinutesRemainder}
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            {entries.map((entry, index) => (
                                                <div
                                                    key={index}
                                                    style={{
                                                        marginBottom: "10px",
                                                        background: "#fff",
                                                        borderRadius: "5px",
                                                        padding: "16px",
                                                    }}
                                                >
                                                    <div
                                                        className=""
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "space-between",
                                                            flexWrap: "wrap",
                                                            gap: "8px",
                                                        }}
                                                    >
                                                        <div
                                                            className=""
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                gap: "4px",
                                                                minWidth: "200px",
                                                            }}
                                                        >
                                                            <span
                                                                className={`description-tooltip-${entry.id}`}
                                                                style={{ fontWeight: "500" }}
                                                            >
                                                                {entry.description.length > 20 ? (
                                                                    <>
                                                                        {entry.description.slice(0, 51)}...
                                                                        <Tooltip
                                                                            target={`.description-tooltip-${entry.id}`}
                                                                            position="top"
                                                                        >
                                                                            {entry.description}
                                                                        </Tooltip>
                                                                    </>
                                                                ) : (
                                                                    entry.description
                                                                )}
                                                            </span>
                                                            <span
                                                                className={`title-tooltip-${entry.id}`}
                                                                style={{
                                                                    background: "#EBE9F5",
                                                                    color: "#6C28D6",
                                                                    borderRadius: "9px",
                                                                    padding: "5px 5px",
                                                                }}
                                                            >
                                                                {projectTitleView(entry.title) && (
                                                                    <>
                                                                        {projectTitleView(entry.title)?.length >
                                                                            10 ? (
                                                                            <>
                                                                                {projectTitleView(entry.title)?.slice(
                                                                                    0,
                                                                                    10
                                                                                )}
                                                                                ...
                                                                                <Tooltip
                                                                                    target={`.title-tooltip-${entry.id}`}
                                                                                    position="right"
                                                                                >
                                                                                    {projectTitleView(entry.title)}
                                                                                </Tooltip>
                                                                            </>
                                                                        ) : (
                                                                            projectTitleView(entry.title)
                                                                        )}
                                                                    </>
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div
                                                            className=""
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                flexWrap: "wrap",
                                                                gap: "8px",
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    background: `${getStatusText(entry.status).bgColor
                                                                        }`,
                                                                    color: `${getStatusText(entry.status).color}`,
                                                                    borderRadius: "5px",
                                                                    padding: " 1px 5px",
                                                                    marginRight: "4px",
                                                                }}
                                                            >
                                                                {getStatusText(entry.status).text}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    letterSpacing: "1.5px",
                                                                    fontWeight: "500",
                                                                }}
                                                            >
                                                                {entry.start_time}-{entry.end_time}
                                                                <span
                                                                    style={{
                                                                        color: "#eee",
                                                                        fontWeight: "500",
                                                                        fontSize: "20px",
                                                                        margin: " 0px 2px ",
                                                                    }}
                                                                >
                                                                    |
                                                                </span>
                                                                <span className="total hrs">
                                                                    {calculateTotalHours(
                                                                        entry.start_time,
                                                                        entry.end_time
                                                                    )}
                                                                </span>{" "}
                                                            </span>
                                                            {entry.status !== "Approved" && entry.status !== "Rejected" && (
                                                                <span
                                                                    style={{
                                                                        display: "flex",
                                                                        gap: "5px",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DailyUpdate;