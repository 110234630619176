import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { MEMO_API } from '../../../constants/api';
import { Tooltip } from 'primereact/tooltip';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';

const AlertMemo = () => {
    const [memoData, setMemoData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const userString = localStorage.getItem("user");
    const userId = JSON.parse(userString).data.id;
    const [expandedRow, setExpandedRow] = useState(null);

    useEffect(() => {
        fetchUserData();
    }, []);

    const fetchUserData = async () => {
        try {
            const { data } = await axios.get(MEMO_API.MEMO_FETCH_DASHBOARD(userId));
            setMemoData([data] || []);
        } catch (error) {
            console.error("Error fetching memo data:", error);
            setError("Error fetching memo data. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    const toggleRow = (id) => {
        setExpandedRow(expandedRow === id ? null : id);
    };

    return (
        <div className="sm:col-12 lg:col-6 mr-2">
            <Link to="/MyMemo" style={{ color: "black" }}>
                <div className="card p-2 align-items-center flex-row" style={{ background: " rgba(228, 248, 240, 0.7)", border: '2px solid #1ea97c', padding: '8px' }}>
                    {/* <i className="fi fi-ts-memo-circle-check memo-icon"></i> */}
                    <div className="vertical-text-Memo">
                        <span style={{ fontSize: '12px', fontWeight: 'bolder' }}>M</span>
                        <span style={{ fontSize: '13px', fontWeight: 'bolder' }}>e</span>
                        <span style={{ fontSize: '13px', fontWeight: 'bolder' }}>m</span>
                        <span style={{ fontSize: '13px', fontWeight: 'bolder' }}>o</span>
                    </div>
                    <div className="mt-1 lg:mt-0 w-screen ">
                    {memoData.length > 0 ? (
                            <div className="overflow-x-auto ">
                            <table className="w-full table-auto border-collapse border border-gray-200">
                                <thead>
                                    <tr>
                                            <th className="border px-4 py-1">Date</th>
                                        <th className="border px-4 py-1">Subject</th>
                                        <th className="border px-4 py-1">Message</th>
                                    </tr>
                                </thead>
                                <tbody style={{ height: '10px', overflowY: 'scroll' }}>
                                    {memoData.map((memo, index) => (
                                        <tr key={memo.id} className="cursor-pointer">
                                            <td className="border px-4 py-1 text-center">{moment(memo.date).format("DD-MM-YYYY")}</td>
                                            <td className="border px-4 py-1 text-center">
                                                <Tooltip target={`.subject-tooltip-${memo.id}`} content={memo.subject} />
                                                <span
                                                    className={`subject-tooltip-${memo.id} cursor-pointer`}
                                                    style={{
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                        display: 'inline-block',
                                                        maxWidth: '200px',
                                                    }}
                                                >
                                                    {memo.subject}
                                                </span>
                                            </td>
                                            <td className="border px-4 py-1 text-center">
                                                <Tooltip target={`.message-tooltip-${memo.id}`} content={memo.message} position='left' />
                                                <span
                                                    className={`message-tooltip-${memo.id} cursor-pointer`}
                                                    style={{
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                        display: 'inline-block',
                                                        maxWidth: '200px',
                                                    }}
                                                >
                                                    {memo.message}
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className='text-center'>No Memo</div>
                        )}
                    </div>
                </div>
            </Link >
        </div>
    );
};

export default AlertMemo;
