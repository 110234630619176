import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import PropTypes from "prop-types";
import layoutNavs from "./LayoutNavs";
import Logo from "../assets/images/HRMSborder.png";
import { useLocation, useNavigate } from "react-router-dom";
import { LOCAL_CONSTANTS } from "../constants/localConstants";
import "../assets/styles/main.css";
import TestLogo from "../assets/images/KodukkuLogoBorderNone.png";

import { ROUTE_CONSTANTS } from "../constants/routeConstants";
const { Sider } = Layout;

function MainLayoutSidebar({ collapsed, setCollapsed }) {
  const [selectedNav, setSelectedNav] = useState(() => {
    return localStorage.getItem(LOCAL_CONSTANTS.APP_NAV) || null;
  });
  const [appNav, setAppNav] = useState([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [expandedHeader, setExpandedHeader] = useState(null);

  const handleLogoClick = () => {
    const userResponse = JSON.parse(localStorage.getItem(LOCAL_CONSTANTS.USER));
    const roleChange = localStorage.getItem(LOCAL_CONSTANTS.ROLE_CHANGE);

    if (userResponse && userResponse.success) {
      const user = userResponse.data;
      const role = user?.role;

      if (role === "admin" || role === "superAdmin") {
        if (roleChange === "user") {
          navigate(ROUTE_CONSTANTS.USER_DASHBOARD);
        } else if (roleChange === "admin") {
          navigate(ROUTE_CONSTANTS.ADMIN_DASHBOARD);
        } else {
          navigate(ROUTE_CONSTANTS.ADMIN_DASHBOARD);
        }
      } else if (role === "admin" || role === "superAdmin") {
        navigate(ROUTE_CONSTANTS.ADMIN_DASHBOARD);
      } else if (role === "user") {
        navigate(ROUTE_CONSTANTS.USER_DASHBOARD);
      }
    }
  };

  useEffect(() => {
    updateAppNavBar();
  }, []);

  useEffect(() => {
    let selected;
    appNav.forEach((doc) => {
      if (!selected) {
        if (Array.isArray(doc.children)) {
          selected = doc.children.find((item) => item.path === pathname);
        } else if (doc.path === pathname) {
          selected = doc;
        }
      }
    });
    if (selected) setSelectedNav(selected.key);
  }, [pathname, appNav]);

  const handleSelect = (e) => {
    const key = e?.key;
    const selectedNavItem = layoutNavs.find((nav) => nav.key === key);
    if (selectedNavItem && selectedNavItem.path) {
      setSelectedNav(selectedNavItem.path);
      navigate(selectedNavItem.path);
    }
  };

  const updateAppNavBar = () => {
    const availableNavs = layoutNavs.map((nav) => {
      return {
        ...nav,
        selected: nav.path === pathname,
      };
    });
    setAppNav(availableNavs);
  };

  const toggleHeader = (header) => {
    setExpandedHeader((prevHeader) => (prevHeader === header ? null : header));
  };

  const groupedNavs = appNav.reduce((acc, nav) => {
    if (!acc[nav.header]) {
      acc[nav.header] = [];
    }
    acc[nav.header].push(nav);
    return acc;
  }, {});

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = ROUTE_CONSTANTS.LOGIN;
  };

  return (
    <Sider
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
        background: "#fff",
      }}
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      theme="dark"
    >
      <div className="p-3 flex justify-content-evenly">
        <a
          href="https://kodukku.com"
          className={`${collapsed ? "hidden" : "inline-block"}`}
          style={{
            height: "46px",
            width: "48px",

          }}
          target="_blank"
        >
          <img
            src={TestLogo}
            alt="Logo"
            style={{
              width: "100%",
              background: "#fff",
              cursor: "pointer",
              height: "100%",
            }}
          />
        </a>

        <img
          src={Logo}
          alt="Logo"
          className={`${collapsed && "w-3rem"}`}
          style={{
            width: "48px",
            background: "#fff",
            cursor: "pointer",
            height: "46px",
          }}
          onClick={handleLogoClick}
        />
      </div>
      <div className="flex justify-content-between flex-column">
        <Menu
          style={{
            height: "72vh",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
          selectedKeys={[selectedNav]}
          onClick={handleSelect}
        >
          {Object.entries(groupedNavs).map(([header, navItems]) => (
            <React.Fragment key={header}>
              <Menu.Item key={header} className="flex align-items-center w-full" onClick={() => toggleHeader(header)}>
                <div
                  className="flex overflow-hidden"
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p className="flex">
                    {
                      layoutNavs.find((nav) => nav.header === header)
                        ?.headerIcon
                    }
                    <h4 className="ml-2">{!collapsed && header}</h4>
                  </p>
                  <p>
                    {expandedHeader === header ? (
                      <i className="fi fi-rr-angle-small-up"></i>
                    ) : (
                      <i className="fi fi-rr-angle-small-down"></i>
                    )}
                  </p>
                </div>
              </Menu.Item>
              {expandedHeader === header &&
                navItems.map((nav) => (
                  <Menu.Item
                    key={nav.key}
                    icon={nav.icon}
                    style={{ marginLeft: "16px" }}
                  >
                    {nav.label}
                  </Menu.Item>
                ))}
            </React.Fragment>
          ))}
        </Menu>
        <div
          onClick={handleLogout}
          style={{
            padding: "10px 14px",
            fontSize: "14px",
            cursor: "pointer",
          }}
        >
          <i className="fi fi-br-sign-out-alt"></i>
          {!collapsed && <b className="ml-2">Logout</b>}
        </div>
      </div>
    </Sider>
  );
}

MainLayoutSidebar.propTypes = {
  collapsed: PropTypes.bool,
  setCollapsed: PropTypes.func,
};

export default MainLayoutSidebar;