import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import axios from "axios";
import { ASSETS_IMAGES_API, ATTENDANCE_API } from "../../../../constants/api";
import { Badge } from "primereact/badge";
import moment from "moment/moment";
import { Image } from "primereact/image";
import AttendanceTime from "../../../Attendence/AttendanceTime";


export default function Attendance({ userId }) {
  const toastRef = useRef(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [timeDetails, setTimeDetails] = useState({})
  const getCurrentDate = new Date();
  const currentMonthSelected = getCurrentDate.toISOString().slice(0, 7);
  const [searchDate, setSearchDate] = useState(currentMonthSelected);
  const [userAttendance, setUserAttendance] = useState([])
  const [count, setCount] = useState({
    presentCount: 0,
    absentCount: 0,
    weekOffCount: 0,
    optionalLeave: 0,
    holidayCount: 0,
    workingDaysCount: 0,
    overTimeCount: 0
  })

  const fetchDataMonth = async () => {

    const [year, month] = searchDate.split("-")
    axios
      .get(ATTENDANCE_API.ATTENDANCE_GET_MONTH(userId, month, year))
      .then(({ data }) => {
        setUserAttendance(data.attendance)
        setTimeDetails(data.time)
        setCount({
          presentCount: data.counts.present,
          absentCount: data.counts.absent,
          weekOffCount: data.counts.weekoff,
          optionalLeave: data.counts.optionalLeave,
          holidayCount: data.counts.holiday,
          workingDaysCount: data.counts.workingDays,
          overTimeCount: data.counts.overtime,

        })
      })
      .catch((error) => {
        console.error("Error fetching user data:", error.message);
      });
  }

  useEffect(() => {
    fetchDataMonth()
  }, [searchDate, userId])

  return (
    <div>
      <div className="card">
        <div className="md:flex block justify-content-between">
          <h2 className="mt-3">Attendance</h2>
          <Toast ref={toastRef} />
          <div className="" style={{ margin: "8px 0" }}>
            <div>
              <label htmlFor="monthPicker">
                <input
                  id="monthPicker"
                  type="month"
                  value={searchDate}
                  onChange={(e) => setSearchDate(e.target.value)}
                  style={{
                    boxShadow: "1px 1px 4px gray",
                    outline: "none",
                    WebkitAppearance: "none",
                    MozAppearance: "none",
                    appearance: "none",
                  }}
                  showButtonBar={false}
                />
              </label>
            </div>
          </div>
        </div>  
        {timeDetails && <div className="">
          <AttendanceTime time={timeDetails} />
        </div>}
        <div className="my-1">
          <div className="flex flex-wrap justify-content-start gap-2">
            <div className="card py-2 px-3 flex-grow-1">
              <div className="flex justify-content-start align-items-center gap-1">
                <div className="">
                  <Badge value={count.presentCount} severity="success" size="large" />
                </div>
                <div>
                  <p className="text-lg">Present</p>
                </div>
              </div>
            </div>

            <div className="card py-2 px-3 flex-grow-1">
              <div className="flex justify-content-start align-items-center gap-1">
                <div className="">
                  <Badge value={count.absentCount} severity="danger" size="large" />
                </div>
                <div>
                  <p className="text-lg">Absent</p>
                </div>
              </div>
            </div>

            <div className="card py-2 px-3 flex-grow-1">
              <div className="flex justify-content-start align-items-center gap-1">
                <div className="">
                  <Badge value={count.overTimeCount} className="bg-purple-500" size="large" />
                </div>
                <div>
                  <p className="text-lg">Overtime</p>
                </div>
              </div>
            </div>

            <div className="card py-2 px-3 flex-grow-1">
              <div className="flex justify-content-start align-items-center gap-1">
                <div className="">
                  <Badge value={count.optionalLeave} severity="secondary" size="large" />
                </div>
                <div>
                  <p className="text-lg">Optional Leave</p>
                </div>
              </div>
            </div>

            <div className="card py-2 px-3 flex-grow-1">
              <div className="flex justify-content-start align-items-center gap-1">
                <div className="">
                  <Badge value={count.weekOffCount} severity="warning" size="large" />
                </div>
                <div>
                  <p className="text-lg">Weekoff</p>
                </div>
              </div>
            </div>

            <div className="card py-2 px-3 flex-grow-1">
              <div className="flex justify-content-start align-items-center gap-1">
                <div className="">
                  <Badge value={count.holidayCount} severity="info" size="large" />
                </div>
                <div>
                  <p className="text-lg">Holidays</p>
                </div>
              </div>
            </div>

            <div className="card py-2 px-3 flex-grow-1">
              <div className="flex justify-content-start align-items-center gap-1">
                <div className="">
                  <Badge value={count.workingDaysCount} size="large" />
                </div>
                <div>
                  <p className="text-lg">Working Days</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <DataTable
          stripedRows
          value={userAttendance}
          editMode="row"
          dataKey="id"
          selectionMode="single"
          selection={selectedRow}
          onSelectionChange={(e) => setSelectedRow(e.value)}
          scrollable
          scrollHeight="600px"
          className="mt-2"
          paginator rows={10} rowsPerPageOptions={[10, 25, 50]}
          globalFilter={searchDate}
          emptyMessage="No records found"
        >
          <Column
            field="date"
            header="Date"
            style={{ minWidth: "105px" }}
            className="font-bold"
            headerStyle={{
              borderTopLeftRadius: "14px",
              borderBottomLeftRadius: "14px",
            }}
            body={(rowData) => moment(rowData.date).format("DD-MM-yyyy")}
          ></Column>
          <Column
            field="check_in"
            header="Check-In"
            style={{ minWidth: "120px" }}
          ></Column>
          <Column
            field="check_out"
            header="Check-Out"
            style={{ minWidth: "120px" }}
            body={(rowData) => (rowData.check_out ? rowData.check_out : "-")}
          ></Column>

          <Column
            field="workinghours"
            header="Working Hours"
            style={{ minWidth: "200px" }}
            body={(rowData) =>
              rowData.workinghours ? rowData.workinghours : "-"
            }
          ></Column>
          <Column
            field="status"
            header="Status"
            style={{ minWidth: "100px" }}
          ></Column>
          <Column
            field="over_time"
            header="OT"
            style={{ minWidth: "200px" }}
            body={(rowData) =>
              rowData.over_time ? rowData.over_time : "-"
            }
          ></Column>
          <Column
            field="remaining_time"
            header="Remaining Time"
            style={{ minWidth: "200px" }}
            body={(rowData) =>
              rowData.remaining_time ? rowData.remaining_time : "-"
            }
          ></Column>
          <Column
            header="CII"
            body={(rowData) => {
              return rowData?.check_in_img !== null ?
                <>
                  <Image src={`${ASSETS_IMAGES_API.ASSETS_IMAGES}${rowData.check_in_img}`} alt="EmployeeImage" width="50" preview />
                </>
                : "-"
            }}
            style={{ width: "70px", textAlign: "center" }}
          />
          <Column
            header="COI"
            body={(rowData) => {
              return rowData.check_out_img !== null ?
                <Image src={`${ASSETS_IMAGES_API.ASSETS_IMAGES}${rowData.check_out_img}`} alt="EmployeeImage" width="50" preview />
                : "-"
            }}
            style={{ width: "50px", textAlign: "center" }}
          />
        </DataTable>

      </div>
    </div>
  );
}
