import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Steps } from "primereact/steps";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { CATEGORY_API, DEPARTMENT_API } from "../../constants/api";
import axios from "axios";
import { EmployeeTypeOptions } from "./data/EmployeeType";
import { DesignationOptions } from "./data/Designation";

export default function EditEmployeeModal({
  visible,
  onHide,
  saveChanges,
  editedData,
  setEditedData,
  reportingManagers,
  errors,
  setErrors,
}) {
  const [currentStep, setCurrentStep] = useState(0);
  const [email, setEmail] = useState(editedData.email);
  const [emailValid, setEmailValid] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState(editedData.contact_number);
  const [phoneNumberValid, setPhoneNumberValid] = useState(true);
  const [departmentData, setDepartmentData] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);

  const getAllDepartment = async () => {
    try {
      const response = await axios.get(DEPARTMENT_API.DEPARTMENT_FETCH);
      setDepartmentData(response.data);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };
  const fetchEmployeesCategory = async () => {
    try {
      const { data } = await axios.get(CATEGORY_API.GET_ALL_CATEGORY);
      setCategoryOptions(data.map(item => ({
        label: item.category_name,
        value: item.category_id
      })));

    } catch (error) {
      console.error("Error fetching employees:", error.message);
    }
  };

  useEffect(() => {
    fetchEmployeesCategory()
    getAllDepartment();
  }, []);
  const selectedDepartment = departmentData.find(
    (department) => department.department_id === parseInt(editedData.department)
  );

  let departmentOptions = [];

  if (selectedDepartment) {
    departmentOptions.push({
      label: `${selectedDepartment.department_name}`,
      value: selectedDepartment.department_id,
    });
  }

  departmentOptions = departmentOptions.concat(
    departmentData
      .filter(
        (department) =>
          department.department_id !== selectedDepartment?.department_id
      )
      .map((department) => ({
        label: `${department.department_name}`,
        value: department.department_id,
      }))
  );

  const steps = [
    { label: "Personal Information" },
    { label: "Employment Details" },
    { label: "Bank Details" },
  ];

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    if (value.replace(/\D/g, "").length < 10) {
      setPhoneNumberValid(false);
    } else {
      setPhoneNumberValid(true);
    }
    setEditedData({ ...editedData, contact_number: value });
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setEmailValid(isEmailValid(newEmail));
    setEditedData({ ...editedData, email: newEmail });
  };

  const [ifscError, setIFSCError] = useState("");
  const validateIFSCCode = (code) => {
    const regex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    return regex.test(code);
  };
  const handleIFSCCodeChange = (e) => {
    const newIFSCCode = e.target.value.toUpperCase();
    if (!validateIFSCCode(newIFSCCode)) {
      setIFSCError("Invalid IFSC code. Please enter a valid IFSC code.");
    } else {
      setIFSCError("");
    }
    setEditedData({ ...editedData, IFSC_Code: newIFSCCode });
  };

  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  };

  const handleSaveChanges = () => {
    let isValid = true;
    if (currentStep === 0) {
      if (
        !editedData.firstname ||
        !editedData.lastname ||
        !editedData.contact_number ||
        !editedData.email ||
        !editedData.DOB ||
        !editedData.gender ||
        !editedData.marital_status ||
        !editedData.address
      ) {
        isValid = false;
        setErrors({
          firstname: !editedData.firstname ? "First name is required" : "",
          lastname: !editedData.lastname ? "Last name is required" : "",
          contact_number: !editedData.contact_number
            ? "Contact number is required"
            : "",
          email: !editedData.email ? "Email is required" : "",
          DOB: !editedData.DOB ? "Date of Birth is required" : "",
          gender: !editedData.gender ? "Gender is required" : "",
          marital_status: !editedData.marital_status
            ? "Marital status is required"
            : "",
          address: !editedData.address ? "Address is required" : "",
        });
      }
    } else if (currentStep === 1) {
      if (
        !editedData.department ||
        !editedData.position ||
        !editedData.employment_status ||
        !editedData.employee_type ||
        !editedData.joining_date ||
        !editedData.salary ||
        !editedData.annual_leave ||
        !editedData.annual_permissions ||
        !editedData.category_id
      ) {
        isValid = false;
        setErrors({
          department: !editedData.department ? "Department is required" : "",
          position: !editedData.position ? "Position is required" : "",
          employment_status: !editedData.employment_status
            ? "Employment status is required"
            : "",
          employee_type: !editedData.employee_type
            ? "Employee type is required"
            : "",
          joining_date: !editedData.joining_date
            ? "Joining date is required"
            : "",
          salary: !editedData.salary ? "Salary is required" : "",
          annual_leave: !editedData.annual_leave
            ? "Annual leave is required"
            : "",
          annual_permissions: !editedData.annual_permissions
            ? "Annual permission is required"
            : "",
          category_id: !editedData.category_id
            ? "Category is required"
            : "",
        });
      }
    } else if (currentStep === 2) {
      if (
        !editedData.bank_name ||
        !editedData.account_number ||
        !editedData.IFSC_Code
      ) {
        isValid = false;
        setErrors({
          bank_name: !editedData.bank_name ? "Bank name is required" : "",
          account_number: !editedData.account_number
            ? "Account number is required"
            : "",
          IFSC_Code: !editedData.IFSC_Code ? "IFSC code is required" : "",
        });
      }
    }

    if (isValid) {
      if (currentStep === 2) {
        saveChanges();
      } else {
        setCurrentStep(currentStep + 1);
      }
    }
  };
  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }}
      header="Edit Employee"
      footer={
        <div>
          {currentStep !== 0 && (
            <Button
              label="Back"
              icon="pi pi-arrow-left"
              className="p-button-text"
              onClick={() => setCurrentStep(currentStep - 1)}
            />
          )}
          <Button
            label={currentStep === 2 ? "Save" : "Next"}
            icon={currentStep === 2 ? "pi pi-check" : "pi pi-arrow-right"}
            className="p-button-success"
            onClick={handleSaveChanges}
          />
          <Button label="Cancel" className="p-button-danger" onClick={onHide} />
        </div>
      }
    >
      <div className="">
        <Steps model={steps} activeIndex={currentStep} />
        {currentStep === 0 && (
          <div className="mt-4">
            <h5></h5>
            <div className="grid">
              <div className="col-12 md:col-4">
                <label htmlFor="firstname">First Name</label>
                <InputText
                  id="firstname"
                  keyfilter={/^[a-zA-Z\s]*$/}
                  value={capitalizeFirstLetter(editedData.firstname)}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      firstname: e.target.value,
                    })
                  }
                  className={errors.firstname ? "p-invalid" : ""}
                />
                {errors.firstname && (
                  <small className="p-error">{errors.firstname}</small>
                )}
              </div>
              <div className="col-12 md:col-4">
                <label htmlFor="lastname">Last Name</label>
                <InputText
                  id="lastname"
                  keyfilter={/^[a-zA-Z\s]*$/}
                  value={capitalizeFirstLetter(editedData.lastname)}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      lastname: e.target.value,
                    })
                  }
                  className={errors.lastname ? "p-invalid" : ""}
                />
                {errors.lastname && (
                  <small className="p-error">{errors.lastname}</small>
                )}
              </div>
              <div className="col-12 md:col-4">
                <label htmlFor="familyname">Family Name</label>
                <InputText
                  id="familyname"
                  keyfilter={/^[a-zA-Z\s]*$/}
                  value={capitalizeFirstLetter(editedData?.familyname) || ""}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      familyname: e.target.value,
                    })
                  }
                  className={errors.familyname ? "p-invalid" : ""}
                />
              </div>
              <div className="col-12 md:col-4">
                <label htmlFor="dateOfBirth">Date of Birth</label>
                <InputText
                  type="date"
                  id="dateOfBirth"
                  value={editedData.DOB}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      DOB: e.target.value,
                    })
                  }
                  className={errors.DOB ? "p-invalid" : ""}
                />
                {errors.DOB && <small className="p-error">{errors.DOB}</small>}
              </div>
              <div className="col-12 md:col-4">
                <label htmlFor="gender">Gender</label>
                <Dropdown
                  className={`w-full ${errors.gender ? "p-invalid" : ""}`}
                  id="gender"
                  value={editedData.gender}
                  options={[
                    { label: "Male", value: "Male" },
                    { label: "Female", value: "Female" },
                    { label: "Transgender", value: "Transgender" },
                  ]}
                  onChange={(e) =>
                    setEditedData({ ...editedData, gender: e.value })
                  }
                />
                {errors.gender && (
                  <small className="p-error">{errors.gender}</small>
                )}
              </div>
              <div className="col-12 md:col-4">
                <label htmlFor="maritalStatus">Marital Status</label>
                <Dropdown
                  className={`w-full ${errors.marital_status ? "p-invalid" : ""
                    }`}
                  id="maritalStatus"
                  value={editedData.marital_status}
                  options={[
                    { label: "Single", value: "Single" },
                    { label: "Married", value: "Married" },
                  ]}
                  onChange={(e) =>
                    setEditedData({ ...editedData, marital_status: e.value })
                  }
                />
                {errors.marital_status && (
                  <small className="p-error">{errors.marital_status}</small>
                )}
              </div>
              <div className="col-12 md:col-6">
                <label>Mobile</label>
                <PhoneInput
                  country={"in"}
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  inputProps={{
                    required: true,
                  }}
                  inputStyle={{
                    border: phoneNumberValid ? "" : "1px solid red",
                  }}
                />
                {!phoneNumberValid && (
                  <small className="p-error">
                    Please enter a valid phone number.
                  </small>
                )}
              </div>
              <div className="col-12 md:col-6">
                <label htmlFor="email">Email</label>
                <InputText
                  id="email"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  className={!emailValid ? "p-invalid" : ""}
                />
                {!emailValid && (
                  <small className="p-error">Invalid email</small>
                )}
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="house_no">House No</label>
                <InputText
                  id="house_no"
                  value={editedData.house_no}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      house_no: e.target.value,
                    })
                  }
                  className={errors.house_no ? "p-invalid" : ""}
                />
                {errors.house_no && (
                  <small className="p-error">{errors.house_no}</small>
                )}
              </div>
              <div className="col-12 md:col-4">
                <label htmlFor="address">Address</label>
                <InputTextarea
                  id="address"
                  value={editedData.address}
                  onChange={(e) =>
                    setEditedData({ ...editedData, address: e.target.value })
                  }
                  rows={1}
                  cols={30}
                  className={errors.address ? "p-invalid" : ""}
                  style={{ resize: "none" }}
                />
                {errors.address && (
                  <small className="p-error">{errors.address}</small>
                )}
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="state">State</label>
                <InputText
                  id="state"
                  keyfilter={/^[a-zA-Z\s]*$/}
                  value={editedData.state}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      state: e.target.value,
                    })
                  }
                  className={errors.state ? "p-invalid" : ""}
                />
                {errors.state && (
                  <small className="p-error">{errors.state}</small>
                )}
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="district">District</label>
                <InputText
                  id="district"
                  keyfilter={/^[a-zA-Z\s]*$/}
                  value={editedData.district}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      district: e.target.value,
                    })
                  }
                  className={errors.district ? "p-invalid" : ""}
                />
                {errors.district && (
                  <small className="p-error">{errors.district}</small>
                )}
              </div>
              <div className="col-12 md:col-2">
                <label htmlFor="pincode">Postal Code</label>
                <InputText
                  id="pincode"
                  value={editedData.pin_code}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      pin_code: e.target.value.replace(/\D/g, "").slice(0, 6),
                    })
                  }
                  className={errors.pin_code ? "p-invalid" : ""}
                />

                {errors.pin_code && (
                  <small className="p-error">{errors.pin_code}</small>
                )}
              </div>
            </div>
          </div>
        )}
        {currentStep === 1 && (
          <div className="mt-4">
            <h5></h5>
            <div className="grid">
              <div className="col-12 md:col-4">
                <label htmlFor="employeeId">Employee ID</label>
                <InputText
                  id="employeeId"
                  value={editedData.empid}
                  onChange={(e) =>
                    setEditedData({ ...editedData, empid: e.target.value })
                  }
                  disabled
                />
              </div>
              <div className="col-12 md:col-4">
                <label htmlFor="kidId">KID</label>
                <InputText
                  id="kidId"
                  value={editedData.kid_id}
                  onChange={(e) =>
                    setEditedData({ ...editedData, kid_id: e.target.value })
                  }
                  disabled
                />
              </div>
              {/* <div className="col-3"></div> */}
              <div className="col-12 md:col-4">
                <label htmlFor="department">Department</label>
                <Dropdown
                  id="department"
                  value={selectedDepartment?.department_id || ""}
                  options={departmentOptions}
                  onChange={(e) =>
                    setEditedData({ ...editedData, department: e.value })
                  }
                  placeholder="Select a Department"
                  className={errors.department ? "w-full p-invalid" : "w-full"}
                />

                {errors.department && (
                  <small className="p-error">{errors.department}</small>
                )}
              </div>
              <div className="col-12 md:col-4">
                <label>Designation</label>

                <InputText
                  value={capitalizeFirstLetter(editedData.position)}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      position: e.target.value,
                    })
                  }
                  className={errors.position ? "p-invalid" : ""}
                />

                {/* <Dropdown
                  id="designation"
                  value={editedData.position}
                  options={DesignationOptions}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      position: e.target.value,
                    })
                  }
                  placeholder="Select a designation"
                  filter
                  showClear
                  className={`${errors.position ? "p-invalid" : ""} w-full`}
                /> */}

                {errors.position && (
                  <small className="p-error">{errors.position}</small>
                )}
              </div>
              <div className="col-12 md:col-4">
                <label htmlFor="employmentStatus">Employment Status</label>
                <Dropdown
                  className={`w-full ${errors.employment_status ? "p-invalid" : ""
                    }`}
                  id="employmentStatus"
                  value={editedData.employment_status}
                  options={[
                    { label: "Active", value: "Active" },
                    { label: "Inactive", value: "Inactive" },
                  ]}
                  onChange={(e) =>
                    setEditedData({ ...editedData, employment_status: e.value })
                  }
                />
                {errors.employment_status && (
                  <small className="p-error">{errors.employment_status}</small>
                )}
              </div>
              <div className="col-12 md:col-4">
                <label htmlFor="employmentType">Employment Type</label>
                {/* <Dropdown
                  className={`w-full ${errors.employee_type ? "p-invalid" : ""
                    }`}
                  id="employmentType"
                  value={editedData.employee_type}
                  options={[
                    { label: "Full-time", value: "Full-time" },
                    { label: "Part-time", value: "Part-time" },
                    { label: "Contract", value: "Contract" },
                  ]}
                  onChange={(e) =>
                    setEditedData({ ...editedData, employee_type: e.value })
                  }
                /> */}
                <Dropdown
                  className={`w-full ${errors.employee_type ? "p-invalid" : ""
                    }`}
                  id="employmentType"
                  value={editedData.employee_type}
                  options={EmployeeTypeOptions}
                  onChange={(e) =>
                    setEditedData({ ...editedData, employee_type: e.value })
                  }
                />
                {errors.employee_type && (
                  <small className="p-error">{errors.employee_type}</small>
                )}
              </div>
              <div className="col-12 md:col-4">
                <label>Joining Date</label>
                <InputText
                  type="date"
                  value={editedData.joining_date}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      joining_date: e.target.value,
                    })
                  }
                  className={errors.joining_date ? "p-invalid" : ""}
                />
                {errors.joining_date && (
                  <small className="p-error">{errors.joining_date}</small>
                )}
              </div>
              <div className="col-12 md:col-4">
                <label htmlFor="empType">Employee Category</label>
                <Dropdown
                  id="category_id"
                  value={parseInt(editedData.category_id)}
                  options={categoryOptions}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      category_id: e.target.value,
                    })
                  }
                  placeholder="Select Employee Category"
                  className={errors.category_id ? "p-invalid w-full" : "w-full"}
                  filter
                  showClear
                />
                {errors.category_id && <small className="p-error">{errors.category_id}</small>}
              </div>
              <div className="col-12 md:col-4">
                <label>Reporting Manager</label>
                <Dropdown
                  className="w-full"
                  value={editedData.reporting_manager_id || ""}
                  options={reportingManagers.map((employee) => ({
                    label: `${employee.firstname} ${employee.lastname}`,
                    value: employee.id,
                  }))}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      reporting_manager_id: e.value,
                    })
                  }
                  placeholder="Select Reporting Manager"
                />
              </div>
              <div className="col-12 md:col-2">
                <label>Notice  Period</label>
                <InputText
                  keyfilter="pnum"
                  value={editedData.notice_period}
                  className="w-full"
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      notice_period: e.target.value,
                    })
                  }

                />
              </div>
              <div className="col-12 md:col-2">
              <label htmlFor="salary">Salary</label>
              <InputNumber
                id="salary"
                className={`w-full ${errors.salary ? "p-invalid" : ""}`}
                value={editedData.salary}
                onValueChange={(e) => {
                  const salary = e.value || 0; 
                  setEditedData((prevData) => {
                    const otHour = salary / 30; 
                    console.log("Updated Salary:", salary, "Calculated OT Per Hour:", otHour);
                    return { ...prevData, salary, ot_hour: otHour };
                  });
                }}
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={2} 
              />
              {errors.salary && <small className="p-error">{errors.salary}</small>}
            </div>
              <div className="col-12 md:col-2">
                <label htmlFor="ot_hour">OT Per Hour</label>
                <InputNumber
                  id="ot_hour"
                  className={`w-full ${errors.ot_hour ? "p-invalid" : ""}`}
                  value={editedData.ot_hour}
                  onValueChange={(e) => {
                    const otHour = e.value || 0;
                    console.log("Manually Updated OT Per Hour:", otHour);
                    setEditedData((prevData) => ({ ...prevData, ot_hour: otHour }));
                  }}
                  mode="decimal"
                  maxFractionDigits={10}
                />
                {errors.ot_hour && <small className="p-error">{errors.ot_hour}</small>}
              </div>
              <div className="col-12 md:col-3">
                <label htmlFor="annualLeave">Annual Leave</label>
                <InputNumber
                  id="annualLeave"
                  value={editedData.annual_leave}
                  onValueChange={(e) =>
                    setEditedData({
                      ...editedData,
                      annual_leave: e.value,
                    })
                  }
                  className={`w-full ${errors.annual_leave ? "p-invalid" : ""}`}
                />
                {errors.annual_leave && (
                  <small className="p-error">{errors.annual_leave}</small>
                )}
              </div>
              <div className="col-12 md:col-3">
                <label htmlFor="annual_permissions">Annual Permissions</label>
                <InputNumber
                  id="annual_permissions"
                  value={editedData.annual_permissions}
                  onValueChange={(e) =>
                    setEditedData({
                      ...editedData,
                      annual_permissions: e.value,
                    })
                  }
                  className={`w-full ${errors.annual_permissions ? "p-invalid" : ""
                    }`}
                />
                {errors.annual_permissions && (
                  <small className="p-error">{errors.annual_permissions}</small>
                )}
              </div>
              <div className="col-12">
                <label htmlFor="responsibilities">Job Responsibilities</label>
                <InputTextarea
                  id="responsibilities"
                  value={editedData.responsibilities}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      responsibilities: e.target.value,
                    })
                  }
                  rows={3}
                  cols={30}
                  className={errors.responsibilities ? "p-invalid" : ""}
                  style={{ resize: "none" }}
                />
                {errors.responsibilities && (
                  <small className="p-error">{errors.responsibilities}</small>
                )}
              </div>
            </div>
          </div>
        )}
        {currentStep === 2 && (
          <div className="mt-4">
            <h5></h5>
            <div className="grid">
              <div className="col-12 md:col-4">
                <label htmlFor="bankName">Bank Name</label>
                <InputText
                  id="bankName"
                  value={capitalizeFirstLetter(editedData.bank_name)}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      bank_name: e.target.value,
                    })
                  }
                  className={errors.bank_name ? "p-invalid" : ""}
                />
                {errors.bank_name && (
                  <small className="p-error">{errors.bank_name}</small>
                )}
              </div>
              <div className="col-12 md:col-4">
                <label htmlFor="ifscCode">IFSC Code</label>
                <InputText
                  id="ifscCode"
                  value={editedData.IFSC_Code}
                  onChange={handleIFSCCodeChange}
                  className={errors.IFSC_Code ? "p-invalid" : ""}
                />
                {ifscError && <small className="p-error">{ifscError}</small>}
              </div>
              <div className="col-12 md:col-4">
                <label htmlFor="accountNumber">Account Number</label>
                <InputText
                  id="accountNumber"
                  value={editedData.account_number}
                  onChange={(e) =>
                    setEditedData({
                      ...editedData,
                      account_number: e.target.value,
                    })
                  }
                  className={errors.account_number ? "p-invalid" : ""}
                />
                {errors.account_number && (
                  <small className="p-error">{errors.account_number}</small>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
}