import React, { useEffect, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import Detail from './Detail';
import LetterTab from './Letter/LetterTab';
import AssetsTab from './Assets/AssetsTab';
import Contact from './Contact';
import Education from './Education';
import Salary from './Salary';
import Job from './Job';
import { useNavigate, useParams, useLocation } from 'react-router';
import { FaUser, FaCalendarCheck, FaTasks, FaEnvelope, FaCubes, FaPhoneAlt, FaGraduationCap, FaDollarSign, FaBriefcase } from 'react-icons/fa';
import axios from 'axios';
import { EMPLOYEE_API } from '../../../constants/api';
import { Button } from 'primereact/button';
import TaskTab from './Task/TaskTab';
import AttendanceTab from './Attendance/AttendanceTab';

const tabHeaders = [
    { label: 'Profile', icon: <FaUser size={15} />, component: Detail, bgColor: '#f44336' },
    { label: 'Attendance', icon: <FaCalendarCheck size={15} />, component: AttendanceTab, bgColor: '#4CAF50' },
    { label: 'Task', icon: <FaTasks size={15} />, component: TaskTab, bgColor: '#2196F3' },
    { label: 'Letter', icon: <FaEnvelope size={15} />, component: LetterTab, bgColor: '#FF9800' },
    { label: 'Assets', icon: <FaCubes size={15} />, component: AssetsTab, bgColor: '#9C27B0' },
    { label: 'Contact', icon: <FaPhoneAlt size={15} />, component: Contact, bgColor: '#00BCD4' },
    { label: 'Education', icon: <FaGraduationCap size={15} />, component: Education, bgColor: '#FFEB3B' },
    { label: 'Salary', icon: <FaDollarSign size={15} />, component: Salary, bgColor: '#3F51B5' },
    { label: 'Job', icon: <FaBriefcase size={15} />, component: Job, bgColor: '#8BC34A' }
];

const EmployeeDetails = () => {
    const navigate = useNavigate();
    const { id: userId } = useParams();
    const location = useLocation();

    const [currentIndex, setCurrentIndex] = useState(0);
    const [profileData, setProfileData] = useState(null);
    const [contactInfo, setContactInfo] = useState([]);
    const [educationInfo, setEducationInfo] = useState([]);
    const [jobInfo, setJobInfo] = useState([]);
    const [relationshipInfo, setRelationshipInfo] = useState([]);
    const [allEmployees, setAllEmployees] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                if (userId) {
                    const response = await axios.get(EMPLOYEE_API.USER_PROFILE(userId));
                    setProfileData(response.data.userData);
                    const kidId = response.data.kid_id;
                    if (kidId) {
                        const { data } = await axios.get(EMPLOYEE_API.GET_USER_DETAILS_KID, { params: { 'user_id': kidId } });
                        setContactInfo(data.contactInfo);
                        setEducationInfo(data.educationInfo);
                        setJobInfo(data.jobInfo);
                        setRelationshipInfo(data.relationshipInfo);
                    }
                }
            } catch (error) {
                console.error("Error fetching user profile:", error);
            }
        };
        fetchProfileData();
    }, [userId]);

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await axios.get(EMPLOYEE_API.GET_ALL);
                if (response.status === 200) {
                    const usersData = response.data.usersData;
                    setAllEmployees(usersData);
                    const currentEmployeeIndex = usersData.findIndex(emp => emp.id === userId);
                    setCurrentIndex(currentEmployeeIndex);
                }
            } catch (error) {
                console.error("Error fetching employees:", error.message);
            }
        };

        fetchEmployees();
    }, []);

    useEffect(() => {
        const tabState = location.state?.activeTab;
        if (tabState !== undefined) {
            setActiveIndex(tabState);
        }
    }, [location.state]);

    const handlePrev = () => {
        if (currentIndex > 0) {
            const prevIndex = currentIndex - 1;
            setCurrentIndex(prevIndex);
            navigate(`/profile/${allEmployees[prevIndex].id}`);
        }
    };

    const handleNext = () => {
        if (currentIndex < allEmployees.length - 1) {
            const nextIndex = currentIndex + 1;
            setCurrentIndex(nextIndex);
            navigate(`/profile/${allEmployees[nextIndex].id}`);
        }
    };

    return (
        <div className="employee-details">
            <div>
                <div className="flex align-items-center justify-content-between my-2 flex-wrap gap-2">

                <div className="flex align-items-center lg:gap-3 md:gap-2 gap-1">
                    <i
                        className="pi pi-arrow-left"
                            style={{ fontSize: "1.7rem", cursor: "pointer" }}
                            onClick={() => navigate('/AllEmployee')}
                    ></i>
                        <span className='font-bold text-2xl' style={{ letterSpacing: '1px', lineHeight: '7px' }}>{profileData?.firstname || ''}{" "}{profileData?.lastname || ''}</span>
                </div>
                    <div className="flex justify-content-end gap-2">
                    <div>
                            <Button onClick={handlePrev} className="border-transparent p-1" icon="pi pi-arrow-circle-left" disabled={currentIndex === 0}
                            />
                    </div>
                    <div>
                            <Button onClick={handleNext} className="border-transparent p-1" icon="pi pi-arrow-circle-right" disabled={currentIndex === allEmployees.length - 1}
                            />
                        </div>
                    </div>
                </div>
                <TabView
                    className='card employee-details'
                    activeIndex={activeIndex}
                    onTabChange={(e) => setActiveIndex(e.index)}
                >
                    {tabHeaders.map((tab, index) => {
                        const Component = tab.component;
                        return (
                            <TabPanel
                                key={index}
                                header={
                                    <div
                                        className="flex align-items-center gap-1"
                                        style={{
                                            backgroundColor: tab.bgColor,
                                            padding: '10px 15px',
                                            borderRadius: '4px',
                                            color: '#fff'
                                        }}
                                    >
                                        {tab.icon} {tab.label}
                                    </div>
                                }
                            >
                                <Component
                                    userId={userId}
                                    contactInfo={contactInfo}
                                    educationInfo={educationInfo}
                                    jobInfo={jobInfo}
                                    relationshipInfo={relationshipInfo}
                                />
                            </TabPanel>
                        );
                    })}
                </TabView>
            </div>
        </div>
    );
};

export default EmployeeDetails;
