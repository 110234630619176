import React from "react";
import {
  UserOutlined,
  ContactsOutlined,
  ScheduleOutlined,
  PayCircleOutlined,
  FormOutlined,
  HomeOutlined,
  SlackOutlined,
  BookOutlined,
  CoffeeOutlined,
  // ReadOutlined,
  ClockCircleOutlined,
  AreaChartOutlined,
  DropboxOutlined,
  FolderOutlined,
} from "@ant-design/icons";

import { ROUTE_CONSTANTS } from "../constants/routeConstants";
import { LOCAL_CONSTANTS } from "../constants/localConstants";
const getUserDetails = () => {
  try {
    const userData = JSON.parse(localStorage.getItem("user"));

    if (userData && userData.success) {
      return {
        success: true,
        data: userData.data,
        role: userData.role,
      };
    } else {
      return { success: false };
    }
  } catch (error) {
    console.error("Error retrieving user details from local storage:", error);
    return { success: false };
  }
};

const user = getUserDetails();
const isAdmin = user?.data?.role === "admin" || user?.data?.role === "superAdmin";
const isadmin = user?.data?.role === "admin" || user?.data?.role === "superAdmin";
const roleChange = localStorage.getItem(LOCAL_CONSTANTS.ROLE_CHANGE)

let adminNavs = [
  {
    key: "2",
    label: "Dashboard",
    path: ROUTE_CONSTANTS.ADMIN_DASHBOARD,
    header: "Home",
    headerIcon: <HomeOutlined style={{ marginRight: "2px", color: "#4CAF50" }} />,
  },
  {
    key: "3",
    label: "All Employees",
    path: ROUTE_CONSTANTS.ALLEMP,
    header: "Employees",
    headerIcon: <UserOutlined style={{ color: "#2692f0" }} />,
  },
  {
    key: "7",
    label: "Departments",
    path: ROUTE_CONSTANTS.DEPARTMENT,
    header: "Employees",
    headerIcon: <UserOutlined style={{ marginRight: "2px" }} />,
  },
  {
    key: "51",
    label: "Staff Categories ",
    path: ROUTE_CONSTANTS.STAFF_CATEGORIES,
    header: "Employees",
    headerIcon: <UserOutlined style={{ marginRight: "2px" }} />,
  },
  {
    key: "22",
    label: "Inactive Employees",
    path: ROUTE_CONSTANTS.INACTIVEEMPLOYEES,
    header: "Employees",
    headerIcon: <UserOutlined style={{ marginRight: "2px" }} />,
  },
  {
    key: "28",
    label: "Today Attendance",
    path: ROUTE_CONSTANTS.ALL_ATTENDANCE,
    header: "Attendance",
    headerIcon: <FormOutlined style={{ color: '#FF9800' }} />,
  },
  {
    key: "8",
    label: "AttendanceSheet",
    path: ROUTE_CONSTANTS.ATTENDANCESHEET,
    header: "Attendance",
    headerIcon: <FormOutlined />,
  },
  {
    key: "26",
    label: "Permission Approvals",
    path: ROUTE_CONSTANTS.All_PERMISSIONS,
    header: "Attendance",
    headerIcon: <FormOutlined />,
  },
  {
    key: "4",
    label: "Leave Approvals",
    path: ROUTE_CONSTANTS.ALLLEAVEREQUEST,
    header: "Attendance",
    headerIcon: <FormOutlined />,
  },
  {
    key: "5",
    label: "Leave Balance",
    path: ROUTE_CONSTANTS.LEAVEBALANCE,
    header: "Attendance",
    headerIcon: <FormOutlined />,
  },
  {
    key: "27",
    label: "Apply Leave",
    path: ROUTE_CONSTANTS.ADMIN_LEAVES,
    header: "Attendance",
    headerIcon: <FormOutlined />,
  },
  // {
  //   key: "9",
  //   label: "Today Attendance",
  //   path: ROUTE_CONSTANTS.TODAYATTENDANCE,
  //   header: "Attendance",
  //   headerIcon: <FormOutlined />,
  // },
  {
    key: "10",
    label: "Employee Payrolls",
    path: ROUTE_CONSTANTS.EMPLOYEEPAYROLL,
    header: "Payroll",
    headerIcon: <PayCircleOutlined style={{ color: "#FF5722" }} />,
  },
  {
    key: "11",
    label: "Pay Heads",
    path: ROUTE_CONSTANTS.PAYHEADS,
    header: "Payroll",
    headerIcon: <PayCircleOutlined />,
  },
  {
    key: "12",
    label: "Projects",
    path: ROUTE_CONSTANTS.PROJECT,
    header: "Project",
    headerIcon: <BookOutlined style={{ color: "#FFC107" }} />,
  },
  {
    key: "18",
    label: "My Projects",
    path: ROUTE_CONSTANTS.MYPROJECT,
    header: "Project",
    headerIcon: <BookOutlined style={{ color: "#FFC107" }} />,
  },
  {
    key: "13",
    label: "All Tasks",
    path: ROUTE_CONSTANTS.ALLTASKS,
    header: "Task",
    headerIcon: <SlackOutlined style={{ color: "#607D8B" }} />,
  },
  {
    key: "38",
    label: "Task Approvals",
    path: ROUTE_CONSTANTS.TASK_APPROVAL,
    header: "Task",
    headerIcon: <ContactsOutlined style={{ marginRight: "2px" }} />,
  },
  {
    key: "31",
    label: "Daily Update",
    path: ROUTE_CONSTANTS.TIME_SHEET_APPROVAL,
    header: "Task",
    headerIcon: <SlackOutlined />,
  },
  {
    key: "46",
    label: "All Assets",
    path: ROUTE_CONSTANTS.ALL_ASSETS_DETAIL,
    header: "Assets",
    headerIcon: <DropboxOutlined style={{ color: "#8BC34A" }} />,
  },
  {
    key: "45",
    label: "All Assets Request",
    path: ROUTE_CONSTANTS.ALL_ASSETS_REQUEST,
    header: "Assets",
    headerIcon: <DropboxOutlined />,
  },
  {
    key: "47",
    label: "All Letters",
    path: ROUTE_CONSTANTS.ALL_LETTER,
    header: "Letters",
    headerIcon: <FolderOutlined style={{ color: "#3F51B5" }} />,
  },
  {
    key: "49",
    label: "All Documents",
    path: ROUTE_CONSTANTS.ALL_PERSONAL_DOCUMENT,
    header: "Letters",
    headerIcon: <FolderOutlined />,
  },
  {
    key: "50",
    label: "All Memos",
    path: ROUTE_CONSTANTS.ALL_MEMO,
    header: "Letters",
    headerIcon: <FolderOutlined />,
  },
  {
    key: "23",
    label: "All Holidays",
    path: ROUTE_CONSTANTS.HOLIDAYS,
    header: "Holidays",
    headerIcon: <CoffeeOutlined style={{ marginRight: "2px", color: "#009688" }} />, 
  },
  {
    key: "29",
    label: "Holidays Calendar",
    path: ROUTE_CONSTANTS.HOLIDAYS_CALENDAR,
    header: "Holidays",
    headerIcon: <CoffeeOutlined style={{ marginRight: "2px" }} />,
  },
  {
    key: "24",
    label: "Company Hierarchy",
    path: ROUTE_CONSTANTS.HIERARCHY,
    header: "Hierarchy",
    headerIcon: <AreaChartOutlined style={{ marginRight: "2px", color: '#ef57f7' }} />,
  },
  // {
  //   key: "26",
  //   label: "Asset Management",
  //   path: ROUTE_CONSTANTS.ASSETMANANGEMENT,
  //   header: "Assets",
  //   headerIcon: <UserOutlined style={{ marginRight: "2px" }} />,
  // }
];

// Conditionally add "Role" navigation item for superAdmin
if (user?.data?.role === "superAdmin") {
  adminNavs = [
    ...adminNavs,
    {
      key: "42",
      label: "Role Permission",
      path: ROUTE_CONSTANTS.ROLE,
      header: "Employees",
      headerIcon: <UserOutlined />,
    },
  ];
}

const userNavs = [
  {
    key: "1",
    label: "Dashboard",
    path: ROUTE_CONSTANTS.USER_DASHBOARD,
    header: "Home",
    headerIcon: <HomeOutlined style={{ marginRight: "2px", color: "#4CAF50" }} />, // Green
  },
  {
    key: "19",
    label: "My Attendance",
    path: ROUTE_CONSTANTS.EMPATTENDANCE,
    header: "Attendance",
    headerIcon: <FormOutlined style={{ color: "#FF9800" }} />, // Orange
  },
  {
    key: "14",
    label: "My Leaves",
    path: ROUTE_CONSTANTS.LEAVE,
    header: "Attendance",
    headerIcon: <FormOutlined />,
  },
  {
    key: "25",
    label: "My Permissions",
    path: ROUTE_CONSTANTS.MY_PERMISSIONS,
    header: "Attendance",
    headerIcon: <FormOutlined />,
  },
  {
    key: "15",
    label: "PaySlip",
    path: ROUTE_CONSTANTS.USER_PAYSLIP,
    header: "My Payrolls",
    headerIcon: <PayCircleOutlined style={{ color: "#FF5722" }} />, // Red
  },
  {
    key: "16",
    label: "Add Task",
    path: ROUTE_CONSTANTS.ADMINTASK,
    header: "Task",
    headerIcon: <SlackOutlined style={{ color: "#607D8B" }} />, // Grey
  },
  {
    key: "17",
    label: "My Task",
    path: ROUTE_CONSTANTS.EMPLOYEETASK,
    header: "Task",
    headerIcon: <SlackOutlined />,
  },
  {
    key: "41",
    label: "Task Approvals",
    path: ROUTE_CONSTANTS.TASK_APPROVER,
    header: "Task",
    headerIcon: <SlackOutlined />,
  },
  {
    key: "30",
    label: "Daily Update",
    path: ROUTE_CONSTANTS.TIME_SHEET,
    header: "Task",
    headerIcon: <SlackOutlined />,
  },
  {
    key: "18",
    label: "My Projects",
    path: ROUTE_CONSTANTS.MYPROJECT,
    header: "Project",
    headerIcon: <BookOutlined style={{ color: "#FFC107" }} />, // Amber
  },
  {
    key: "46",
    label: "My Assets",
    path: ROUTE_CONSTANTS.MY_ASSETS_DETAIL,
    header: "Assets",
    headerIcon: <DropboxOutlined style={{ color: "#8BC34A" }} />, // Light Green
  },
  {
    key: "45",
    label: "Assets Request",
    path: ROUTE_CONSTANTS.EMPLOYEE_ASSETS_REQUEST,
    header: "Assets",
    headerIcon: <DropboxOutlined />,
  },
  {
    key: "48",
    label: "My Letters",
    path: ROUTE_CONSTANTS.EMPLOYEE_LETTER,
    header: "Letters",
    headerIcon: <FolderOutlined style={{ color: "#3F51B5" }} />,
  },
  {
    key: "49",
    label: "My Documents",
    path: ROUTE_CONSTANTS.EMPLOYEE_PERSONAL_DOCUMENT,
    header: "Letters",
    headerIcon: <FolderOutlined />,
  },
  {
    key: "50",
    label: "My Memos",
    path: ROUTE_CONSTANTS.MY_MEMO,
    header: "Letters",
    headerIcon: <FolderOutlined />,
  },
  {
    key: "23",
    label: "All Holidays",
    path: ROUTE_CONSTANTS.USERHOLIDAYS,
    header: "Holidays",
    headerIcon: <CoffeeOutlined style={{ marginRight: "2px", color: "#009688" }} />, // Orange
    // Yellow
  },
  {
    key: "29",
    label: "Holidays Calendar",
    path: ROUTE_CONSTANTS.HOLIDAYS_CALENDAR,
    header: "Holidays",
    headerIcon: <CoffeeOutlined style={{ marginRight: "2px" }} />,
  },

  // {
  //   key: "12",
  //   // icon: <FaSheetPlastic />,
  //   label: "Employee Payslip",
  //   path: ROUTE_CONSTANTS.EMPPAYSLIP,
  //   header: "Payroll",
  //   headerIcon: <PayCircleOutlined />,
  // },
  // {
  //   key: "13",
  //   // icon: <FaMoneyBill />,
  //   label: "SalaryDetails",
  //   path: ROUTE_CONSTANTS.SALARYCERTI,
  //   header: "Payroll",
  //   headerIcon: <PayCircleOutlined />,
  // },
  //  {
  //   key: "12",
  //   // icon: <FaMoneyBill />,
  //   label: "Task",
  //   path: ROUTE_CONSTANTS.ADMINTASK,
  //   header: "Task",
  //   headerIcon: <SlackOutlined />,
  // },
];

let layoutNavs = [];

if (isadmin) {
  if (roleChange === "user") {
    layoutNavs = userNavs;
  } else if (roleChange === "admin") {
    layoutNavs = adminNavs;
  }
} else if (isAdmin) {
  layoutNavs = adminNavs;
} else {
  layoutNavs = userNavs;
}


export default layoutNavs;
