import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { PAYROLL_API } from '../../../constants/api';
import { DataTable } from 'primereact/datatable';
import Column from 'antd/es/table/Column';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

const Salary = ({ userId }) => {
  const [userPayrollData, setUserPayrollData] = useState([])
  const [globalFilter, setGlobalFilter] = useState("")
  const fetchALlData = async () => {
    axios
      .get(PAYROLL_API.GET_ALL_PAYROLL_EMP(userId))
      .then(({ data }) => {
        setUserPayrollData(data.payrollDetails)
      })
      .catch((error) => {
        console.error("Error fetching user data:", error.message);
      });
  }
  useEffect(() => {
    fetchALlData()
  }, [userId])

  return (
    <div>
      <div className="flex block justify-content-end align-items-center mb-2">
        <div className="w-15rem">
          <div className="p-inputgroup">
            <InputText type="text" id="globalFilter" className="search-input" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Search" />
            <Button icon="fi fi-bs-search" className="search-icon" />
          </div>
        </div>
      </div>
      <DataTable
        value={userPayrollData}
        globalFilter={globalFilter.trim() ? globalFilter : null}
        paginator
        rows={10}
        rowsPerPageOptions={[10, 25, 50]}
        emptyMessage="No data available"
      >
        <Column
          field="from_date"
          header="From Date"
          bodyStyle={{ minWidth: "120px" }}
          sortable
        ></Column>
        <Column
          field="to_date"
          header="To date"
          bodyStyle={{ minWidth: "120px" }}
          sortable
        ></Column>
        <Column
          field="month"
          header="Month"
          bodyStyle={{ minWidth: "120px" }}
          sortable
        ></Column>
        <Column
          field="salary"
          header="Salary"
          bodyStyle={{ minWidth: "90px" }}
          sortable
        ></Column>
        <Column field="total_deductions" header="Deductions"></Column>
        <Column field="netpay" header="Net pay"></Column>
        <Column
          header="Payslip"
          body={(rowData, rowIndex) => (
            <>
              <div className="flex justify-content-start align-items-center">
                <Link to={`/PaySlip/${rowData.payroll_id}`} state={{ userId }}  >
                  <Button
                    tooltip="View"
                    tooltipOptions={{
                      position: "bottom",
                      mouseTrack: true,
                      mouseTrackTop: 15,
                    }}
                    className="p-button-primary"
                    style={{
                      borderRadius: "50%",
                      padding: "12px 15px",
                      background: "#FD8E2D",
                      border: "#FD8E2D",
                    }}
                  >
                    <i className="fi fi-rr-eye"></i>
                  </Button>
                </Link>
              </div>
            </>
          )}
        ></Column>
      </DataTable>
    </div >
  )
}

export default Salary