import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { LEAVE_API } from "../../../../constants/api";
import { Tooltip } from "primereact/tooltip";
import moment from "moment/moment";

export default function Leave({ userId }) {
  const [products, setProducts] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [remainingAnnualLeave, setRemainingAnnualLeave] = useState([]);
  const [newLeaveData, setNewLeaveData] = useState({
    LeaveFrom: moment().format("YYYY-MM-DD"),
    LeaveTo: moment().format("YYYY-MM-DD")
  });
  const [sortField, setSortField] = useState("fromdate");
  const [sortOrder, setSortOrder] = useState(-1);

  const onSort = (e) => {
    const { field, order } = e.sort;
    setSortField(field);
    setSortOrder(order);
  };

  const calculateNoOfDaysForNewLeave = (fromDate, toDate) => {
    const from = new Date(fromDate);
    const to = new Date(toDate);

    const fromUTC = Date.UTC(
      from.getFullYear(),
      from.getMonth(),
      from.getDate()
    );
    const toUTC = Date.UTC(to.getFullYear(), to.getMonth(), to.getDate());

    const timeDiff = Math.abs(toUTC - fromUTC);
    const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) + 1;

    setNewLeaveData((prevData) => ({ ...prevData, NoOfDays: diffDays }));
  };

  useEffect(() => {
    calculateNoOfDaysForNewLeave(newLeaveData.LeaveFrom, newLeaveData.LeaveTo);
  }, [newLeaveData.LeaveFrom, newLeaveData.LeaveTo]);

  useEffect(() => {
    const fetchLeaveData = () => {
      axios
        .get(LEAVE_API.LEAVE_FETCH(userId))
        .then((response) => {
          setProducts(response.data.leaveData);
          setRemainingAnnualLeave(response.data);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error.message);
        });
    };
    fetchLeaveData();
  }, [userId]);

  const getStatusColor = (rowData) => {
    switch (rowData.status) {
      case "pending":
        return {
          borderColor: "#91CAFF",
          backgroundColor: "#E6F4FF",
          color: "#4280E3",
        };
      case "approved":
        return {
          borderColor: "#B7EB8F",
          backgroundColor: "#F6FFED",
          color: "#389E0D",
        };
      case "rejected":
        return {
          borderColor: "#FF3D32",
          backgroundColor: "#FAFAFA",
          color: "#FF3D32",
        };
      case "request":
        return {
          borderColor: "#fd7e14",
          backgroundColor: "#FAFAFA",
          color: "#fd7e14",
        };
      default:
        return {
          borderColor: "#ffbf00",
          backgroundColor: "#E6F4FF",
          color: "#4280E3",
        };
    }
  };

  return (
    <div>
      <div className="flex flex-column sm:flex-row gap-2 my-2 sm:my-3 justify-content-between ">
        <div className="flex-order-1 sm:flex-order-0">
          <p
            className="mr-3 flex"
            style={{
              borderRadius: "5px",
              color: "#030303",
              fontWeight: "500",
              fontSize: "1.2rem",
            }}
          >
            <span>Leave Balance :
            </span>
            <span
              style={{
                color: "#000",
                fontWeight: "700",
                fontSize: "1.3rem",
                marginLeft: "5px",
              }}
            >
              {remainingAnnualLeave.balanceLeave || 12}
            </span>
          </p>
        </div>
        <div className="w-15rem">
          <div className="p-inputgroup">
            <InputText
              type="text"
              className="search-input p-inputtext p-component"
              placeholder="Search"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
            <Button icon="pi pi-search" className="search-icon" />
          </div>
        </div>
      </div>
      <div className="mt-4">
        <DataTable
          value={products}
          paginator
          rows={5}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={onSort}
          globalFilter={globalFilter.trim() ? globalFilter : null}
          emptyMessage="No data available"
        >
          <Column
            field="fromdate"
            header="From Date"
            style={{ minWidth: "120px" }}
            body={(rowData) => moment(rowData.fromdate).format("DD-MM-YYYY")}
          />
          <Column
            field="todate"
            header="To Date"
            style={{ minWidth: "110px" }}
            body={(rowData) => moment(rowData.todate).format("DD-MM-YYYY")}
          />
          <Column
            field="noOfdays"
            header="No of Days"
            style={{ minWidth: "120px" }}
          />
          <Column
            field="leave_request_type"
            header="Time Off"
            style={{ minWidth: "100px" }}
          />
          <Column
            field="leavetype"
            header="Leave Type"
            style={{ minWidth: "100px" }}
          />
          <Column
            field="filename"
            header="Medical Certificate"
            body={(rowData) => rowData.filename ?? "-"}
            style={{ minWidth: "100px" }}
          />
          <Column
            field="reason"
            header="Reason"
            body={(rowData) => (
              <div
                id={`reason_${rowData.leaveRequestId}`}
                className="tooltip-container"
              >
                {rowData.reason.length > 20
                  ? `${rowData.reason.slice(0, 20)}...`
                  : rowData.reason}
                <Tooltip
                  target={`#reason_${rowData.leaveRequestId}`}
                  position="right"
                >
                  {rowData.reason}
                </Tooltip>
              </div>
            )}
          />
          <Column
            field="status"
            header="Status"
            body={(rowData) => (
              <span
                id={`status_${rowData.leaveRequestId}`}
                style={{
                  border: "2px solid",
                  ...getStatusColor(rowData),
                  padding: "6px 12px",
                  borderRadius: "4px",
                  position: "relative",
                  cursor: "pointer"
                }}
              >
                {rowData.status.charAt(0).toUpperCase() +
                  rowData.status.slice(1).toLowerCase()}

                {rowData.status === "rejected" && (
                  <Tooltip target={`#status_${rowData.leaveRequestId}`} position="top">
                    <p style={{ color: "red", fontWeight: "bold" }}>
                      Rejected Reason:
                    </p>{" "}
                    <span style={{ textTransform: "capitalize" }}>
                      {rowData.rejection_reason}
                    </span>
                  </Tooltip>
                )}
              </span>
            )}
            style={{ minWidth: "100px", textAlign: "center" }}
          />
        </DataTable>
      </div>
    </div>
  );
}
